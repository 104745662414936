/* eslint-disable no-param-reassign */
import { accessObjectByString } from 'services/helpers'
import theme from 'theme/designTokens'
import { utilsPDF } from '../utils'

const { createEmptyCells, borderLayoutDefault } = utilsPDF

function addColSpan(column, columnArray) {
  const emptyCellsNumber = column.columnStyle.colSpan - 1
  const emptyCells = createEmptyCells(emptyCellsNumber)
  columnArray.push(...emptyCells)
}

function addRowSpan(column, tableColumns, index) {
  const emptyCellsNumber = column.columnStyle.rowSpan - 1

  for (let i = 1; i <= emptyCellsNumber; i += 1) {
    const rowIndex = index + i

    if (!tableColumns[rowIndex]) {
      tableColumns[rowIndex] = []
    }

    tableColumns[rowIndex].push(createEmptyCells(1).at(0))

    if (column.columnStyle.colSpan) {
      addColSpan(column, tableColumns[rowIndex])
    }
  }
}

export default function buildTable({
  data,
  columns,
  tableStyle,
  columnStyle,
  rowStyle
}) {
  const borderColor = Array(5).fill(theme.palette.gray.bgLight)

  const tableColumns = []
  const tableRowsColumns = []
  const tableRows = []
  const tableWidths = columnStyle?.widths ?? '*'
  const tableHeights = [columnStyle?.height ?? 'auto']

  function addColumn(column, columnArray, index) {
    columnArray.push({
      text: column.label,
      bold: true,
      fillColor: theme.palette.background.tableHeaderPDF,
      borderColor,
      ...columnStyle,
      ...column.columnStyle
    })

    if (column.columnStyle) {
      if (column.columnStyle.colSpan) {
        addColSpan(column, columnArray)
      }

      if (column.columnStyle.rowSpan) {
        addRowSpan(column, tableColumns, index)
      }
    }
  }

  columns.forEach((column, index) => {
    if (Array.isArray(column)) {
      if (!tableColumns[index]) {
        tableColumns[index] = []
      }

      column.forEach((columnItem) => {
        if (
          columnItem.rowLabelKey ||
          columnItem.rowLabelFormatter instanceof Function
        ) {
          tableRowsColumns.push(columnItem)
        }

        addColumn(columnItem, tableColumns[index], index)
      })
    } else {
      if (column.rowLabelKey || column.rowLabelFormatter instanceof Function) {
        tableRowsColumns.push(column)
      }

      addColumn(column, tableColumns, index)
    }
  })

  data.forEach((item, rowIndex) => {
    let rowHeight = 'auto'

    if (rowStyle?.heights) {
      rowHeight = rowStyle.heights[rowIndex]
    } else if (rowStyle?.height) {
      rowHeight = rowStyle.height
    }

    tableHeights.push(rowHeight)

    if (!tableRows[rowIndex]) {
      tableRows[rowIndex] = []
    }

    tableRowsColumns.forEach((column) => {
      const label = accessObjectByString(item, column.rowLabelKey)
      let rowLabel = label

      if (column.rowLabelFormatter) {
        rowLabel = column.rowLabelFormatter(label, item)
      }

      let newRowStyle = column.rowStyle

      if (column.rowStyle instanceof Function) {
        newRowStyle = column.rowStyle(label, item)
      }

      tableRows[rowIndex].push({
        text: rowLabel,
        borderColor,
        ...rowStyle,
        ...newRowStyle
      })

      if (column.columnStyle?.colSpan) {
        tableRows[rowIndex].at(-1).colSpan = column.columnStyle.colSpan
        addColSpan(column, tableRows[rowIndex])
      }
    })
  })

  const getPadding = (paddingIndex) => (index) => {
    const columnPaddingDoesNotExists = !columnStyle?.padding?.[index]
    const rowPaddingDoesNotExists = !rowStyle?.padding?.[index]

    if (columnPaddingDoesNotExists && rowPaddingDoesNotExists) return 5

    if (!columnPaddingDoesNotExists && index === 0)
      return columnStyle.padding[paddingIndex]

    return rowStyle.padding[paddingIndex]
  }

  const columnsToSpread = Array.isArray(tableColumns[0])
    ? tableColumns
    : [tableColumns]

  return {
    fontSize: 7,
    table: {
      widths: tableWidths,
      heights: tableHeights,
      body: [...columnsToSpread, ...tableRows]
    },
    layout: {
      paddingLeft: getPadding(0),
      paddingTop: getPadding(1),
      paddingRight: getPadding(2),
      paddingBottom: getPadding(3),
      ...borderLayoutDefault
    },
    ...tableStyle
  }
}
