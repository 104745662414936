import React, { Fragment, useMemo, useState } from 'react'
import { CardAccordion, CardIlustrationFeedback } from './styles'
import { Box, Typography } from '@mui/material'
import {
  dateAddDays,
  formatNumberToScore,
  isDateAfter,
  parseDateStringToISODate
} from 'services/helpers'
import { cardIlustrationFeedbacks } from './constants'

export default function DisciplineCard({ data, hasColor, indicatorColor }) {
  const [expanded, setExpanded] = useState(false)

  const { studentStatus, finalStudentStatus, isPartial, isNextDiscipline } =
    useMemo(() => {
      // const currentDate = new Date()
      // const parsedEndDate = parseDateStringToISODate(
      //   data.disciplineInfo.endDate
      // )
      // const currentDateIsAfter = isDateAfter(currentDate, parsedEndDate)
      // const scoreIsBelow = data.grades.finalGrade < 6
      // const scoreIsToRecovery = data.grades.finalGrade > 4 && scoreIsBelow
      // const scoreIsToReclassification = data.grades.finalGrade > 6
      // const excededAbscences =
      //   data.frequency.absence > data.frequency.maximumAllowedAbsences

      let studentStatus = null
      let finalStudentStatus = null

      if (data.grades.type === 'studying') {
        if (data.disciplineInfo.inReclassification) {
          studentStatus = 'Em reclassificação'
        } else if (data.disciplineInfo.inAcademicRecovery) {
          studentStatus = 'Em recuperação'
        }
      }

      // if (scoreIsBelow && excededAbscences) {
      //   finalStudentStatus = 'Reprovado por nota e falta'
      // } else if (scoreIsBelow) {
      //   finalStudentStatus = 'Reprovado por nota'
      // } else if (scoreIsToRecovery) {
      //   finalStudentStatus = 'Reprovado por falta'
      // }

      return {
        studentStatus,
        finalStudentStatus,
        isPartial: ['studying', 'to_attend'].includes(data.grades.type),
        isNextDiscipline: data.grades.type === 'to_attend'
      }
    }, [data])

  function handleWithScore(score) {
    const emptyValues = [null, undefined, '']

    if (emptyValues.includes(score) || isNextDiscipline) {
      return '-'
    }

    const formattedScore = formatNumberToScore(score, 1)
    return formattedScore
  }

  return (
    <CardAccordion
      expanded={expanded}
      onChange={(_, expanded) => setExpanded(expanded)}
      hasIcon={false}
      hasColor={hasColor}
      indicatorColor={indicatorColor}
      disableGutters
      HeaderComponent={
        <>
          <Typography component="h4">{data.disciplineInfo.name}</Typography>
          {studentStatus && (
            <Typography component="span">{studentStatus}</Typography>
          )}
          <Box>
            <Typography component="span">
              {handleWithScore(
                isPartial ? data.grades.partialGrade : data.grades.finalGrade
              )}
            </Typography>
            <Typography component="span">
              Nota <br />
              {isPartial ? 'parcial' : 'final'}
            </Typography>
          </Box>
          <Box>
            {!data.disciplineInfo.isInternship && (
              <>
                <Typography component="span">
                  {isNextDiscipline ? (
                    '-'
                  ) : (
                    <>
                      {data.frequency.absence}/
                      <Typography component="span">
                        {data.frequency.maximumAllowedAbsences}
                      </Typography>
                    </>
                  )}
                </Typography>
                <Typography component="span">
                  Faltas <br /> permitidas
                </Typography>
              </>
            )}
          </Box>
        </>
      }
      BodyComponent={
        <Box>
          {!data.grades.activities.length || isNextDiscipline ? (
            <CardIlustrationFeedback
              src={
                isNextDiscipline
                  ? cardIlustrationFeedbacks[data.grades.type].src
                  : cardIlustrationFeedbacks.default.src
              }
              message={
                isNextDiscipline
                  ? cardIlustrationFeedbacks[data.grades.type].message
                  : cardIlustrationFeedbacks.default.message
              }
              complement={
                isNextDiscipline
                  ? cardIlustrationFeedbacks[data.grades.type].complement
                  : cardIlustrationFeedbacks.default.complement
              }
              orientation="row"
            />
          ) : (
            <>
              {data.grades.activities.map((activity) => (
                <Fragment key={activity.activity_id}>
                  <Typography component="p">
                    {activity.activity_type_name}
                  </Typography>
                  <Typography component="span">
                    {handleWithScore(activity.student_grade)}/
                    <Typography component="span">
                      {activity.activity_max_grade}
                    </Typography>
                  </Typography>
                </Fragment>
              ))}
              {finalStudentStatus && (
                <Typography component="span">{finalStudentStatus}</Typography>
              )}
            </>
          )}
        </Box>
      }
    />
  )
}
