export const translate = (transcriptions, response) => {
  const toTranslate = JSON.stringify(response)

  const translator = (translation, transcription) => {
    const originalKey = `"${transcription.key}":`
    const translationKey = `"${transcription.translation}":`
    const validator = new RegExp(originalKey, 'ig')
    return translation.replace(validator, translationKey)
  }

  const translations = transcriptions.reduce(translator, toTranslate)
  return JSON.parse(translations)
}

export const isObject = (payload) =>
  typeof payload === 'object' && payload.constructor === Object

export const objectIsEmpty = (payload) =>
  isObject(payload) && !Object.keys(payload).length

export const accessObjectByString = (object, inputString) => {
  let attribute

  if (object instanceof Object && inputString) {
    const splitedString = inputString.split('.')

    attribute = splitedString.reduce((previous, current) => {
      if (previous) return previous[current]
      return previous
    }, object)
  }
  return attribute
}
